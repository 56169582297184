@use 'css/abstracts' as *;

// .p-job-detail__jobDetailAction
//  .o-jobDetailAction.-float
.o-jobDetailAction.-float {
  --_button-garter: var(--global-spacing-1);
  $__button_garter: 4;

  .o-jobDetailAction__jmc {
    margin-top: px(20);
  }

  .o-jobDetailAction__buttons {
    display: flex;
    justify-content: space-between;
    gap: var(--_button-garter);
  }

  .o-jobDetailAction__button {
    --_button-h: 40px;
    flex: 0 0 max-content;
    min-width: 0;

    .a-btn,
    .m-buttonBookmark {
      padding: 0 var(--_button-garter);
      width: 100%;
      height: var(--_button-h);
    }

    &.-ask {
      flex: 1 1 auto;

      .a-btn {
        font-size: rem(14);
      }
    }

    &.-bookmark {
      $w: 96px;
      max-width: $w;
    }

    &.-bookmarkList {
      $w: 96px;
      max-width: $w;
    }
  }
}
