@use "css/mixin/core/hover" as *;

// カードUIやパネル系UIのhover時の共通インタラクション
@mixin ui-is-hover-interaction() {
  opacity: var(--global-ui-hover-opacity);
  cursor: pointer;
}

@mixin common-link-text-interaction {
  color: var(--global-color-primary);
  text-decoration: underline;
  @include hover {
    text-decoration: none;
  }
}

@mixin link-stretched() {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
  }
}

@mixin can-click-element {
  cursor: pointer;
}
