@use 'css/abstracts' as *;
$o-header__logo_w: 200;
$o-header__logo_w--full: 400;
$o-header__head_pd-vertical: 12;
$o-header__head_pdb-vertical: $o-header__head_pd-vertical + 6;
$o-header__head_pd-vertical--full: 20;
$o-header__head_pdb-vertical--full: 0;

footer {
  &.-border-top{
    border-top: 1px solid var(--color-gray_cccccc, #CCC);
  }
	background: #fff;
	.footer-navi,
	.footer-navi * {
		box-sizing: border-box;
	}

	.footer-navi {
		padding: 48px 48px 44px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		flex-shrink: 0;
		max-width: 1296px;
		position: relative;
		margin: auto;
		@media (max-width: 1199px) {
			flex-direction: column;
			gap: 24px;
			padding: 32px 22px;
		}
	}

	.navi-info {
		display: flex;
		flex-direction: row;
		gap: 24px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		a:hover {
			opacity: 0.6;
		}
		@media (max-width: 1199px) {
			flex-direction: column;
			gap: 20px;
		}
	}

	.logo-footer {
		flex-shrink: 0;
		width: 263px;
		height: 38px;
		position: relative;
		object-fit: cover;
	}

	.address {
		display: flex;
		flex-direction: column;
		gap: 8px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		@media (max-width: 1199px) {
			text-align: center;
			justify-content: center;
			align-items: center;
		}
	}

	.div {
		color: #333333;
		text-align: left;
		font-size: 16px;
		line-height: 120%;
		font-weight: 700;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	._690-0061-71-3-f {
		color: #333333;
		text-align: left;
		font-size: 14px;
		line-height: 120%;
		font-weight: 400;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.navi-menu {
		display: flex;
		flex-direction: row;
		gap: 40px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}

	.frame-392 {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}

	.frame-3922 {
		padding: 2px 0px 0px 0px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.ellipse-1 {
		border-radius: 50%;
		border-style: solid;
		border-color: var(--color-keyred, #d10000);
		border-width: 2px;
		flex-shrink: 0;
		width: 9px;
		height: 9px;
		position: relative;
	}

	.div2 {
		display: block;
		color: var(--color-black, #1f1f1f);
		text-align: left;
		font-size: 13px;
		line-height: 140%;
		font-weight: 400;
		position: relative;
		&:hover {
			opacity: 0.6;
		}
	}

	.frame-393 {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}

	.frame-3932 {
		padding: 2px 0px 0px 0px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}
	.copy {
		display: flex;
		height: 60px;
		padding: 0px 48px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		align-self: stretch;
		background: var(--color-black, #1f1f1f);
		color: var(--color-white, #fff);
		text-align: center;
		font-family: Helvetica;
		font-size: 11px;
		font-style: normal;
		font-weight: 300;
		line-height: 10px; /* 90.909% */
	}
}
