@use 'css/abstracts' as *;
$o-header__logo_w: 200;
$o-header__logo_w--full: 400;
$o-header__head_pd-vertical: 12;
$o-header__head_pdb-vertical: $o-header__head_pd-vertical + 6;
$o-header__head_pd-vertical--full: 20;
$o-header__head_pdb-vertical--full: 0;

.o-header {
	--_narrow-p-r: 64px;
	background-color: var(--global-color-white);
	color: var(--global-color-black);
	padding: 0 16px;
	height: 50px;
	@media (min-width: 1200px) {
		padding: 8px 48px;
		height: 76px;
	}
	.inr {
		max-width: 1200px;
		margin: auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
    height: 100%;
	}
	.logo-marc {
		display: block;
		// width: 90px;
		width: 179px;
		@media (min-width: 1200px) {
			width: 212px;
		}
		&:hover {
			opacity: 0.6;
		}
	}
}
.gnavi {
	display: flex;
	gap: 18px;
	justify-content: center;
	align-items: center;

	@media (max-width: 1199px) {
		display: none;
	}
}
.gnavi-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	height: 36px;
	position: relative;
}

.-item {
	padding: 0 24px;
	border-left: 1px solid #d10000;
	color: var(--black400, #111111);
	text-align: left;
	font-size: 14px;
	line-height: 120%;
	font-weight: 600;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	&:hover {
		opacity: 0.6;
	}
}

.o-menuToggle {
	position: fixed;
	top: 13px;
	right: 16px;
	width: 32px;
	height: 22px;
	z-index: 1000;
	cursor: pointer;
	span {
		position: relative;
		display: block;
		height: 2px;
		background: var(--color-key_red, #D10000);
		margin-top: 10px;
		margin-bottom: 10px;
		&::before,
		&::after {
			content: '';
			position: absolute;
			display: block;
			width: 100%;
			height: 2px;
			background: var(--color-key_red, #D10000);
			left: 0;
		}
		&::before {
			top: -10px;
		}
		&::after {
			bottom: -10px;
		}
	}
	@media (min-width: 1200px) {
		display: none;
	}
}

.is-active {
	.o-menuToggle > span {
		// display: none;
		background: transparent;
	}
	.o-menuToggle span:after {
		top: 0;
		transform: rotate(-45deg);
	}
	.o-menuToggle span:before {
		top: 0;
		transform: rotate(45deg);
	}
}

.btn-cv {
	&:hover {
		opacity: 0.6;
	}
	background: var(--color-keygold, #a59450);
	padding: 2px;
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	width: 200px;
	height: 60px;
	position: relative;
	.btn-inr {
		border-style: solid;
		border-color: rgba(255, 255, 255, 0.5);
		border-width: 1px;
		padding: 4px 8px 4px 8px;
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		justify-content: center;
		align-self: stretch;
		flex: 1;
		position: relative;
	}

	.medal {
		background: var(--color-white, #ffffff);
		border-radius: 21px;
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		width: 42px;
		height: 42px;
		position: relative;
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		color: #222222;
		text-align: left;
		font-size: 15px;
		line-height: 100%;
		font-weight: 600;
		position: relative;
	}

	.lavel {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		color: #ffffff;
		text-align: left;
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 16px;
		line-height: 140%;
		font-weight: 600;
	}
}
