@use 'css/abstracts' as *;

.p-home {
  .p-home-simpleSearch {
    margin-top: 48px;
  }

  //.p-home-section__header {
  //  &.-no-bar {
  //    border-bottom: 0 !important;
  //    &::after {
  //      content: none !important;
  //    }
  //  }
  //}
}

.p-home-heroimage {
  @include full-width-container;
  z-index: 1;
}

.p-home-simpleSearch {
  margin-top: 48px;
  padding: 48px 40px;
  background-color: var(--global-color-white);
  border: 1px solid var(--global-color-primary);

  @at-root {
    .p-home-simpleSearch__title {
      margin-inline: auto;
      margin-bottom: px(16);
      max-width: px(1156);
      font-weight: 700;
    }
  }
}
