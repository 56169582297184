@use 'css/abstracts' as *;

$_heroCarousel-picture-bp: 704; // NOTE Swiperのbreakpointsの設定も、ここに合わせる必要あり

.o-heroCarousel {
  @include swiper-pagination-style;

  @at-root {
    .o-heroCarousel {

    }

    .o-heroCarousel__list {

    }

    .o-heroCarousel__item {
      max-width: px(704);

      &:hover {
        @include ui-is-hover-interaction;
      }

      picture, img {
        aspect-ratio: 88/85;
        display: block;
        width: 100%;
        max-height: px(680);
        height: auto;

        @include mq($from: $_heroCarousel-picture-bp * 1px) {
          aspect-ratio: 25/14;
          max-height: px(394);
          height: px(394);
        }
      }
    }

    .o-heroCarousel__pagination {
      margin-top: px(20);
    }
  }

  /// 各スライド内部用
  @at-root {
    .o-heroCarousel__item {
      // 1枚目のスライド用のクラス
      .first-panel {

      }
    }
  }
}
