// TODO lazysizesの利用がなくなったら、削除すること。loading="lazy"に置き換えること
.no-js img.lazyload {
  display: none;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity .4s;
}
