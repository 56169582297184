.section_heading {
  position: relative;
	text-align: center;
	.ttl_sub {
		display: inline-block;
		padding: 16px 24px;
		border-top: 1px solid var(--color-key_red, #d10000);
		border-bottom: 1px solid var(--color-key_red, #d10000);
		color: var(--color-key_red, #d10000);
		text-align: center;
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%; /* 24px */
		margin-bottom: 32px;
		@media (max-width: 1199px) {
			font-size: 18px;
			line-height: 1.4;
			padding: 10px 0;
			margin-bottom: 18px;
      width: 100%;
      max-width: 400px;
		}
	}
	h2 {
		color: var(--color-black, #1f1f1f);
		text-align: center;
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 38px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%; /* 45.6px */
		margin-bottom: 16px;
    .-s{
      font-size: 28px;
    }
		@media (max-width: 1199px) {
			font-size: 30px;
			margin-bottom: 14px;
      .-s{
        font-size: 24px;
      }
		}
	}
	.tag {
		display: inline-flex;
		background: var(--color-black, #1f1f1f);
		padding: 3px 20px;
		color: var(--color-white, #fff);
		text-align: center;
		font-family: Montserrat;
		font-size: 15px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%; /* 18px */
		@media (max-width: 1199px) {
			font-size: 14px;
		}
	}
}
