@use 'css/abstracts' as *;

$_bp: 'md';

.a-homeSectionHeading {

  display: flex;
  flex-direction: column;
  gap: 0 var(--global-spacing-4);
  align-items: center;

  font-weight: 700;
  font-size: var(--global-typofgraphy-heading-2);
  line-height: var(--global-typofgraphy-lineHeight);

  @include mq($_bp) {
    flex-direction: row;
  }
}

.a-homeSectionHeading__label {

}

.a-homeSectionHeading__labelEn {
  font-family: 'Cormorant Garamond', serif;
  letter-spacing: -0.02em;
  font-weight: 400;
  color: var(--global-color-primary);
  @include mq($until: $_bp) {
    order: -1;
  }
}
