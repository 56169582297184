@use 'css/abstracts' as *;

$t-hasSidebar--maxw: 1280;

/// サイドバーありレイアウト
.t-hasSidebar {
  --template-layout-main-space: 50px;
  --template-layout-gutter-both-ends: var(--global-gutter-outer-xs);
  --template-layout-content-maxw: 800px;
  --template-layout-sidebar-maxw: 310px;
  @include layout-site-header-full-breakpoint {
    --template-layout-gutter-both-ends: var(--global-gutter-outer-md);
  }

  .site-main {
    margin-inline: auto;
    padding-inline: var(--template-layout-gutter-both-ends);
    max-width: px($t-hasSidebar--maxw);
  }

  // 2カラムレイアウトを作るため
  // 1160px想定
  .site-main__inner {
    margin: var(--template-layout-main-space) auto;
    max-width: calc(var(--template-layout-main-space) + var(--template-layout-content-maxw) + var(--template-layout-sidebar-maxw));

    // この時点で、
    @include layout-site-header-full-breakpoint {
      display: flex;
      //gap: var(--template-layout-main-space); // NOTE max-widthとspace-betweenに任せる
      margin-inline: calc(var(--template-layout-main-space) * -1);
      justify-content: space-between;
    }
    @include mq(xxl) {
      margin-inline: auto;
    }
  }

  .site-main-content {
    max-width: var(--template-layout-content-maxw);

    @include layout-site-header-full-breakpoint-less {
      margin-inline: auto;
    }

    @include layout-site-header-full-breakpoint {
      flex: 1 1 auto;
      min-width: 0;
    }
  }


  .site-sidebar {
    max-width: var(--template-layout-content-maxw);

    @include layout-site-header-full-breakpoint-less {
      margin-inline: auto;
    }

    @include layout-site-header-full-breakpoint {
      position: relative;
      flex: 0 0 px(310);
      min-width: 0;
      max-width: var(--template-layout-sidebar-maxw);
    }
  }

  .site-main-footer__content {
    margin-left: 0;
  }

  .o-footer {
    margin-top: var(--template-layout-main-space);
  }
}

