@use "css/vendors" as *;

@mixin appear($mq-breakpoint-name) {
  @include mq($until: $mq-breakpoint-name) {
    display: none;
  }
}

@mixin disappear($mq-breakpoint-name) {
  @include mq($mq-breakpoint-name) {
    display: none;
  }
}
