@mixin centerer() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin position-vertical-center() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/**
 * どのような構造で配置されていても、左右画面いっぱいに広がるようにする
 */
@mixin full-width-container() {
  position: relative;
  left: 50%;
  right: 50%;
  margin: {
    right: -50vw;
    left: -50vw;
  }
  //max-width: 100%;
  width: 100vw;
}

@mixin absolute($t:auto,$r:auto,$b:auto,$l:auto) {
  position: absolute;
  top: $t;
  right: $r;
  bottom: $b;
  left: $l;
}
