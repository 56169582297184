// 文字を省略する
//
// text-overflow http://msdn.microsoft.com/ja-jp/library/gg721774(v=expression.40).aspx
// text-overflow - CSS | MDN https://developer.mozilla.org/ja/docs/Web/CSS/text-overflow
@mixin ellipsis($ltr: ellipsis) {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: $ltr;
  -ms-text-overflow: $ltr; // IE6+
  -webkit-text-overflow: $ltr;
  text-overflow: $ltr;
}

@mixin line-clamp($lines: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
