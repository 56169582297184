// @use "css/style/module/form/1-foundation" as foundation;
// @use "css/style/module/form/5-design" as design;
// 全画面共通読み込みを想定している
@use "css/style/common/1-foundation" as foundation;
@use "css/style/common/3-vendor" as vendor;
@use "css/style/common/5-design" as design;
//@use "css/entries/common/5-design" as design;
// @tailwind utilities;

@use "header" as *;
@use "footer" as *;
@use "menu" as *;
.p-top{
  background-size: auto;
  background-image: url(../img/common/bg_sp.png);
  background-size: 200px auto;
  // background:#aaa;
}
.lp-main{
max-width: 640px;
margin: auto;
  @media (min-width: 1200px) {
    max-width: 100%;
  }
}
