// @see https://www.cssui.dev/docs/components/accordion/
// アコーディオンのみ抽出・利用
[data-accordion] {
  --cssui-animation-timing: 0.3s;
  --cssui-animation-rotate: rotate(-180deg);
  --accordion-panel-padding-y: 1em;
}

[data-accordion-item] {
  width: 100%;
}

[data-accordion-item]:not(:last-child) {
}

[data-accordion-item] > input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

[data-accordion-item] > label {
  //display: flex;
  //justify-content: space-between;
  //align-items: center;

  cursor: pointer;
}

[data-accordion-item] > label:hover,
[data-accordion-item] > input:checked + label {
}

[data-accordion-item] > input:checked + label:hover {
}

/* Accordion Item's Label Focus State */
[data-accordion-item] > input:focus + label {
  outline: 2px solid #101010;
  outline: 2px solid highlight;
  outline: 2px solid -webkit-focus-ring-color;
}

/* Hide focus ring if focus comes from a pointer device. */
[data-accordion-item] > input:focus:not(:focus-visible) + label {
  outline: none;
}

[data-accordion-item] > label > svg {
  transition: all var(--cssui-animation-timing) ease-out;
}

[data-accordion-panel] {
  max-height: 0;
  transition: padding var(--cssui-animation-timing) ease;
  visibility: hidden;

  padding-block: 0;
}

[data-accordion-item] > input:checked + label > svg {
  transform: rotate(-180deg);
}

[data-accordion-item] > input:checked ~ [data-accordion-panel] {
  max-height: 100vh;
  visibility: visible;

  padding-block: var(--accordion-panel-padding-y);
}
