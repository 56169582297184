@use 'css/abstracts' as *;

$_clickable-size: 40;

.m-buttonBookmarkIcon {
  @include can-click-element;
  font-size: rem(24);
  color: var(--global-color-primary);
  position: relative;
  width: px($_clickable-size);
  height: px($_clickable-size);
  // @note 流石にこの小さなアイコンひとつだけでクリックさせるのは使いづらすぎ。$_clickable-size x $_clickable-sizeにリンク範囲をストレッチさせる

  &::after {
    content: "";
    @include centerer;
    display: block;
    width: px($_clickable-size);
    height: px($_clickable-size);
    z-index: 3;
  }

  &[data-bookmarked="false"] {
    .-saved {
      display: none;
    }
  }

  &[data-bookmarked="true"] {
    .-save {
      display: none;
    }
  }

  .m-buttonBookmarkIcon__label {
    .a-icon {
      width: px($_clickable-size);
      height: px($_clickable-size);
    }
  }
}
