.a-badgePoint {
  --_theme-color: var(--global-color-text);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-inline: 8px;
  border: 1px solid currentColor;
  border-radius: 2px;
  color: var(--_theme-color);
  font-size: var(--global-typography-fontSize-sm);
  line-height: var(--global-typofgraphy-lineHeight);

  &.-theme-primary {
    --_theme-color: var(--global-color-red-400);
  }
}
