@use 'css/abstracts' as *;
.o-menuNavwrap {
	display: none;
	overflow-x: hidden;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 999;

	height: 100vh;
	height: 100lvh;
	padding: 106px 22px 0;
	width: 100vw;
  background-color: #D10000;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(../img/common/bg_navigation.jpg);
	background-position: center;
	.is-active & {
		display: block;
	}
	@media (min-width: 1200px) {
		display: none !important;
	}
	.g-navi,
	.g-navi * {
		box-sizing: border-box;
	}

	.g-navi {
    max-width: 480px;
    margin: auto;
		display: flex;
		flex-direction: column;
		gap: 48px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.navi-menu {
		border-style: solid;
		border-color: var(--semantic-color-border-field-stroke, #cccccc);
		border-width: 0px 0px 1px 0px;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.li {
		border-style: solid;
		border-color: var(--semantic-color-border-field-stroke, #cccccc);
		border-width: 1px 0px 0px 0px;
		padding: 0px 6px 0px 6px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		height: 60px;
		position: relative;
	}

	.div {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-size: 18px;
		line-height: 120%;
		font-weight: 600;
		position: relative;
	}

	._6674 {
		flex-shrink: 0;
		width: 14px;
		height: 14px;
		position: relative;
		overflow: visible;
	}

	._66742 {
		flex-shrink: 0;
		width: 14px;
		height: 14px;
		position: relative;
		overflow: visible;
	}

	._3 {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-size: 18px;
		line-height: 120%;
		font-weight: 600;
		position: relative;
	}

	._66743 {
		flex-shrink: 0;
		width: 14px;
		height: 14px;
		position: relative;
		overflow: visible;
	}

	._66744 {
		flex-shrink: 0;
		width: 14px;
		height: 14px;
		position: relative;
		overflow: visible;
	}

	._66745 {
		flex-shrink: 0;
		width: 14px;
		height: 14px;
		position: relative;
		overflow: visible;
	}

	.btan-base {
		background: var(--color-keygold, #a59450);
		padding: 3px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		flex-shrink: 0;
		height: 80px;
		max-width: 400px;
		position: relative;
		box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
    &:hover{
      opacity: .6;
    }
	}

	.inr {
		border-style: solid;
		border-color: var(--color-keygoldlight, #eeebe0);
		border-width: 1px;
		display: flex;
		flex-direction: row;
		gap: 16px;
		align-items: center;
		justify-content: center;
		align-self: stretch;
		flex: 1;
		position: relative;
		overflow: hidden;
    padding: 0 21px;
	}

	.medal {
		background: var(--color-white, #ffffff);
		border-radius: 27px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		width: 54px;
		height: 54px;
		position: relative;
	}

	.div2 {
		color: #222222;
		text-align: left;
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 19px;
		line-height: 100%;
		font-weight: 600;
		position: relative;
	}

	.lavel {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		justify-content: center;
		// flex-shrink: 0;
		position: relative;
	}

	.div3 {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 20px;
		line-height: 140%;
		font-weight: 600;
		position: relative;
	}

	.icon-arrow-blue-svg {
		flex-shrink: 0;
		width: 16px;
		height: 16px;
		position: relative;
		overflow: visible;
	}
}

// .o-menuNav__item{
//     & + .o-menuNav__item{
//         margin-top: 32px;
//     }

// }
// .o-menuNav__link {
// 	position: relative;
// 	padding-left: 24px;

//   color: var(--black, #1C1F1E);
// text-align: center;
// font-feature-settings: 'clig' off, 'liga' off;
// font-family: Noto Sans JP;
// font-size: 16px;
// font-style: normal;
// font-weight: 700;
// line-height: 1.5; /* 24px */
// letter-spacing: 0.8px;
// 	&::before {
// 		content: '';
// 		position: absolute;
// 		left: 0;
//         top: 0;
//         bottom: 0;
// 		margin: auto;
// 		display: block;
// 		width: 12px;
// 		height: 12px;
// 		background-repeat: no-repeat;
// 		background-size: contain;
// 		background-image: url(../img/top/icon_arrow2.svg);
// 	}
// }
