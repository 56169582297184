@use 'css/abstracts' as *;

$_bp: 'md';
$_bp-to-horizontal: 'lg';
$_bp_home: 'xl';
$__input_size: 270;

.o-simpleSearchForm {
  --_cross-icon-size: 14px;
  --_form-field-gap: var(--global-spacing-2);
  @include mq($_bp-to-horizontal) {
    --_form-field-gap: var(--global-spacing-4);
  }

  .a-formSelect {
    width: 100%;
  }

  @at-root {

    %inputFormInit {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: var(--_form-field-gap);
      align-items: center;
      @include mq($_bp-to-horizontal) {
        flex-direction: row;
      }

      &:hover {
        .o-simpleSearchForm__hoverShowItem {
          opacity: 1;
        }
      }
    }

    .o-simpleSearchForm__hoverShowItem {
      @include appear($_bp);
      position: absolute;
      bottom: calc(100% - #{rem(4)});
      left: calc(100% - #{rem(40)});
      width: rem(320);
      // max-width: rem(320);
      opacity: 0;
      transition: opacity .3s;
      // width: 100%;
    }


    .o-simpleSearchForm__industry,
    .o-simpleSearchForm__areas {
      @extend %inputFormInit;
      //@include mq($until: $_bp) {
      //  flex: 0 0 calc(100% / 3);
      //margin-right: 3%;
      //}
    }

    .o-simpleSearchForm__areas {
      @extend %inputFormInit;
      @include mq($until: $_bp) {
        display: none;
      }
    }

    .o-simpleSearchForm__location {
      @extend %inputFormInit;
      margin-right: 0;
      @include mq($until: $_bp) {
        flex: 0 0 59%;
      }

      &::before, &::after {
        display: none;
      }

      .o-simpleSearchForm__hoverShowItem {
        left: calc(100% - #{rem(100)});
        @include mq($until: $_bp_home) {
          left: rem(20);
        }
      }
    }


  }
}

.o-simpleSearchForm__inner {
  display: flex;
  flex-direction: column;
  gap: var(--global-spacing-4);
  @include mq($_bp-to-horizontal) {
    gap: var(--_form-field-gap);
  }
  @include mq($_bp_home) {
    flex-direction: row;
    justify-content: center;
    align-items: center;

  }
}

.o-simpleSearchForm__inputs {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--_form-field-gap);

  @include mq($_bp-to-horizontal) {
    flex: 1 1 auto;
    min-width: 0;

    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }

  .icon-divider {
    position: relative;
    //margin-inline: auto;
    width: var(--_cross-icon-size);
    height: var(--_cross-icon-size);

    &::before, &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: var(--_cross-icon-size);
      height: 1px;
      background-color: var(--global-color-gray-400);
    }

    &::before {
      transform: translateY(-50%) rotate(45deg);
    }

    &::after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  //.a-select__input {
  //  @include mq($until: $_bp) {
  //    font-size: rem(14);
  //    padding-top: rem(4);
  //    padding-bottom: rem(4);
  //  }
  //}
}

.o-simpleSearchForm__submit {
  @include mq($_bp_home) {
    max-width: 100%;
    flex: 1 1 auto;
    min-width: 0;
  }

  @at-root {
    .o-simpleSearchForm__button {
      width: 100%;
    }
  }
}
