@use "css/funcion" as *;
/// 19/08/19のトップページ新デザインで登場したPaginationのデザイン
@mixin swiper-pagination-style() {
  /// TODO Swiper用。コンポーネントに切ったりmixinかなにかにする
  .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-pagination-bullet {
    background-color: var(--global-color-gray-500);
    border-radius: 50%;
    width: rem(8);
    height: rem(8);
  }

  .swiper-pagination-bullet-active {
    background-color: var(--global-color-primary);
  }
}
