@use 'css/abstracts' as *;

// gmj/btnの移行先予定
.a-btn {
  /*
  ボタンの状態向け
   */
  &.-disabled {
    pointer-events: none;
    opacity: var(--global-ui-disabled-opacity);
  }

  /*
  ボタンのアイコン向け
   */
  &.-withIcon {
    gap: 8px; // verticalに対応できるように
  }

  &.-iconRight {
    gap: var(--_icon-left-distance, 0);
  }
}

/*
  ボタンのサイズ向け
*/
// header m-linkButtonToPostAJob
.a-btn.-size-8 {
  --_space-x: var(--global-spacing-3);
  --_space-y: var(--global-spacing-1-5);
  --_icon-size: 8px;
  --_icon-distance: var(--global-spacing-1);
  padding: var(--_space-y) var(--_space-x);
  min-height: 32px;

  .a-icon {
    font-size: var(--_icon-size);
  }

  &.-iconFixedRight {
    padding-inline: calc(var(--_icon-distance) * 2 + var(--_icon-size));

    .a-icon {
      right: var(--_icon-distance);
    }
  }
}

// 2022/09 mypage向けとして登場
.a-btn.-size-10 {
  --_icon-size: var(--global-typography-fontSize-lg);
  --_space-x: var(--global-spacing-3);
  padding: .5em 1em;
  font-size: var(--global-typography-fontSize-default);
  line-height: var(--global-typofgraphy-lineHeight);
  min-height: 40px;

  .a-icon {
    font-size: var(--global-typography-fontSize-lg);
  }

  &.-iconFixedRight {
    padding-inline: calc(var(--_space-x) * 2 + var(--_icon-size));

    & > .a-icon {
      right: var(--_space-x);
    }
  }

  &.-iconFixedLeft {
    padding-inline: calc(var(--_space-x) * 2 + var(--_icon-size));

    .a-icon {
      left: var(--_space-x);
    }
  }
}
