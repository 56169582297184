@use "sass:math";
@use 'css/abstracts' as *;

$m-jobInfoDetail_layout-bp: "sm";
$m-jobInfoDetail_bp--full: 'xl';
$m-jobInfoDetail__header_gap: 20;

.m-jobInfoDetail {
  $_bp: "md";
  $_icon_fz: 15;
  $_icon_fz--full: $_icon_fz;
  $_icon_mr: 5;
  $_icon_mr--full: $_icon_mr;


  $__company_fz: 18;
  $__company_fz--full: 20;
  $__company_lh: math.div(24, $__company_fz);
  $__company_lh--full: math.div(24, $__company_fz--full);

  @at-root {
    $m-jobInfoDetail__data__fz: 15;
    $m-jobInfoDetail__data__fz--full: $m-jobInfoDetail__data__fz;
    $__description_fz: 19;
    $__description_fz--full: 24;
    $__description_lh: math.div(30, $__description_fz);
    $__description_lh--full: math.div(40, $__description_fz--full);

    .m-jobInfoDetail__description {
      margin-block: var(--global-spacing-5);
      font-size: rem($__description_fz);
      line-height: $__description_lh;

      @include mq($_bp) {
        margin-block: var(--global-spacing-7);
        font-size: rem($__description_fz--full);
        line-height: $__description_lh--full;
      }

      .a-icon {
        @include disappear($_bp);
        // NOTE: 位置調整のため。vertical-alignだと微妙
        position: relative;
        top: -.1em;
        font-size: rem(20);
        color: var(--global-color-primary);
        @include mq($_bp) {
          font-size: rem($_icon_fz--full);
        }
      }
    }

    .m-jobInfoDetail__company {
      display: flex;
      align-items: flex-start;

      // 会社名
      .heading {
        //margin-top: px(0 - calcOneSideBetweenTheLines($__company_fz,$__company_lh));
        //margin-bottom: px(0 - calcOneSideBetweenTheLines($__company_fz,$__company_lh));
        font-size: rem($__company_fz);
        line-height: $__company_lh;
        @include mq($_bp) {
          //margin-top: px(0 - calcOneSideBetweenTheLines($__company_fz--full,$__company_lh--full));
          //margin-bottom: px(0 - calcOneSideBetweenTheLines($__company_fz--full,$__company_lh--full));
          font-size: rem($__company_fz--full);
          line-height: $__company_lh--full;
        }
      }
    }

    // Newや副業といったbadgeの表示
    .m-jobInfoDetail__badges {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: px(10);

      // TODO:
      .a-badge {
        width: px(45);
        @include mq($_bp) {
          width: px(50);
        }

        & + .a-badge {
          margin-left: px(10);
        }
      }
    }

    .m-jobInfoDetail__data {
      $__li_margin--vertical: 10; // flexitemのmarginは相殺しない
      display: flex;
      flex-wrap: wrap;
      margin-right: px($__li_margin--vertical * -1);
      margin-left: px($__li_margin--vertical * -1);
      font-size: rem($m-jobInfoDetail__data__fz); //@design same

      @include mq($until: $_bp) {
        margin-bottom: px($m-jobInfoDetail__data__fz * -1);
      }

      @include mq($_bp) {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
      }

      li {
        margin-right: px($__li_margin--vertical);
        margin-left: px($__li_margin--vertical);
        position: relative;
        padding-left: rem($_icon_fz + $_icon_mr);
        line-height: 1.2;

        @include mq($_bp) {
          padding-left: rem($_icon_fz--full + $_icon_mr--full);
        }

        &:not(:last-child) {
          @include mq($until: $_bp) {
            margin-bottom: px($m-jobInfoDetail__data__fz);
          }
        }
      }

      .a-icon {
        position: absolute;
        top: 0;
        left: 0;
        margin-right: 1em;
        font-size: rem($_icon_fz);
        //vertical-align: top;
        vertical-align: bottom;
        color: var(--global-color-primary);

        @include mq($_bp) {
          margin-right: px($_icon_mr--full);
        }
      }
    }

    .m-jobInfoDetail__pref,
    .m-jobInfoDetail__income {
      flex: 0 1 auto;
    }

    .m-jobInfoDetail__job {
      @include mq($until: $m-jobInfoDetail_layout-bp) {
        margin-bottom: px($m-jobInfoDetail__data__fz);
        flex: 1 1 100%;
        max-width: 100%;
      }
    }

    .m-jobInfoDetail__pref {
      margin-right: px(20);
      @include mq($m-jobInfoDetail_layout-bp) {
        margin-right: px(40);
      }
    }
  }

  &.-noThumb {
    .m-jobInfoDetail__header {
      display: grid;
      grid-gap: 0 rem(20);
      gap: 0 rem(20);
      grid-template: "jobInfoDetailNoThumbCompany" auto "jobInfoDetailNoThumbData" auto / 1fr;

      //@include mq($m-jobInfoDetail_layout-bp) {
      //  grid-gap: 0 rem(20);
      //gap: 0 rem(20);
      //grid-template: "jobInfoDetailNoThumbCompany" auto "jobInfoDetailNoThumbData" auto / 1fr;
      //}
    }

    .m-jobInfoDetail__company {
      grid-area: jobInfoDetailNoThumbCompany;
    }

    .m-jobInfoDetail__data {
      grid-area: jobInfoDetailNoThumbData;
      //margin-top: px(
      //    15 -
      //    0 //calcOneSideBetweenTheLines($__company_fz, $__company_lh)
      //);
      margin-top: var(--global-spacing-3);

      //@include mq($m-jobInfoDetail_layout-bp) {
      //  margin-top: px(
      //      12 -
      //  0 //calcOneSideBetweenTheLines($__company_fz--full, $__company_lh--full)
      //  );
      //}
    }

    .m-jobInfoDetail__tags {
      margin-top: px(20);
      @include mq($_bp) {
        margin-top: px(30);
      }
    }

    .m-jobInfoDetail__body {
      margin-top: px(25);
      border-top: rem(1) solid #000;

      @include mq($_bp) {
        margin-top: px(30);
      }
    }
  }

  &.-large {
    $__logo_w: 200;

    .m-jobInfoDetail__header {
      display: grid;
      grid-gap: 0 0;
      gap: 0 0;
      grid-template: "jobInfoDetailLargeLogo" auto "jobInfoDetailLargeCompany" auto "jobInfoDetailLargeData" auto "jobInfoDetailLargeDescription" auto "jobInfoDetailLargeTags" auto
        / 1fr;

      @include mq($_bp) {
        grid-gap: 0 px(30);
        gap: 0 px(30);
        grid-template: "jobInfoDetailLargeLogo jobInfoDetailLargeCompany" auto "jobInfoDetailLargeLogo jobInfoDetailLargeData" auto "jobInfoDetailLargeLogo jobInfoDetailLargeDescription" auto "jobInfoDetailLargeLogo jobInfoDetailLargeTags" auto
          / #{px($__logo_w)} 1fr;
      }
    }

    .m-jobInfoDetail__tags {
      grid-area: jobInfoDetailLargeTags;
    }

    .m-jobInfoDetail__data {
      grid-area: jobInfoDetailLargeData;
    }

    .m-jobInfoDetail__company {
      grid-area: jobInfoDetailLargeCompany;
      margin-bottom: var(--global-spacing-3);

      @include mq($until: $_bp) {
        //margin-top: px(30 - calcOneSideBetweenTheLines($__company_fz, $__company_lh));
        margin-top: var(--global-spacing-7);
      }

      //@include mq($_bp) {
      //margin-bottom: px(15 - calcOneSideBetweenTheLines($__company_fz--full, $__company_lh--full));
      //margin-bottom: var(--global-spacing-3);
      //}
    }

    $__description_fz: 14;
    $__description_fz--full: 17;
    $__description_lh: math.div(24, $__description_fz);
    $__description_lh--full: math.div(22, $__description_fz--full);

    .m-jobInfoDetail__description {
      grid-area: jobInfoDetailLargeDescription;
      //margin-top: px(
      //    25 - calcOneSideBetweenTheLines($__description_fz, $__description_lh)
      //);
      //margin-bottom: px(
      //    25 - calcOneSideBetweenTheLines($__description_fz, $__description_lh)
      //);
      margin-block: var(--global-spacing-5);
      font-size: rem($__description_fz);
      line-height: $__description_lh;

      @include mq($_bp) {
        //margin-top: px(
        //    30 -
        //    calcOneSideBetweenTheLines(
        //      $__description_fz--full,
        //      $__description_lh--full
        //    )
        //);
        //margin-bottom: px(
        //    30 -
        //    calcOneSideBetweenTheLines(
        //      $__description_fz--full,
        //      $__description_lh--full
        //    )
        //);
        margin-block: var(--global-spacing-7);
        font-size: rem($__description_fz--full);
        line-height: $__description_lh--full;
        font-weight: 700;
      }
    }

    .m-jobInfoDetail__logo {
      grid-area: jobInfoDetailLargeLogo;
      height: auto;
      text-align: center;

      @include mq($_bp) {
        width: px($__logo_w);
      }
    }
  }
}

.m-jobInfoDetail__img {
  object-fit: cover;
  aspect-ratio: 25 / 14; // NOTE https://gmj2022.develop-env.info/form/?offer_code=3556
}
