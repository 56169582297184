.m-pageButton {
  --_bg-color: var(--global-color-white);
  --_color: var(--global-color-black);
  --_bd-color: currentColor;
  --_size: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--_size);
  height: var(--_size);

  font-size: var(--global-typography-fontSize-default);
  line-height: 1;

  border: 1px solid var(--_bd-color);
  background-color: var(--_bg-color);
  color: var(--_color);

  &__label {
  }

  &.-current {
    --_bg-color: var(--global-color-primary);
    --_color: var(--global-color-white);
    --_bd-color: var(--global-color-primary);
  }

  &.-prev,
  &.-next {
    //font-size: var(--global-typography-fontSize-xl);
  }
}
