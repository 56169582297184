@use 'css/abstracts' as *;

$_bp--full: "xs";
$__item_w: 68;
$o-pagination__item_garter: 12;
$o-pagination__item_garter--full: 10;

.o-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.o-pagination__list {
  max-width: 330px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--global-spacing-3);

  @include mq($until: $_bp--full) {
    display: grid;
    grid-template:
      "paginationPrev paginationNext" auto
      "paginationPages paginationPages" auto / 1fr 1fr;
  }
}

.o-pagination__pages {
  order: 2;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--global-spacing-3);

  @include mq($until: $_bp--full) {
    justify-content: flex-start;
    grid-area: paginationPages;
  }
}

.o-pagination__item {
  flex: 0 0 auto;
  max-width: max-content;

  &.-prev {
    margin-right: auto;

    @include mq($until: $_bp--full) {
      grid-area: paginationPrev;
      margin-right: auto;
      padding-right: 0;
    }
  }

  &.-next {
    order: 4;
    @include mq($until: $_bp--full) {
      grid-area: paginationNext;
      margin-left: auto;
      padding-left: 0;
    }
  }

  // @note ComponentRootの幅に応じた処理。Element Queryがほしい
  //&:nth-child(4) {
  //  @include mq($until: $__item_w * 4) {
  //    display: none;
  //  }
  //}
  //&:nth-child(5) {
  //  &.-last {
  //    @include mq($until: $__item_w * 5) {
  //      display: none;
  //    }
  //  }
  //}
}
