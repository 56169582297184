// gmj/btnContainer
//@mixin buttonContainer($modifier: false) {
//  @if $modifier == 'md' {
//    margin-right: auto;
//    margin-left: auto;
//    max-width: rem(300);
//  }
//  @if $modifier == 'lg' {
//    margin-right: auto;
//    margin-left: auto;
//    max-width: rem(460);
//  }
//}

/// ボタンの幅を取るためのコンテナ
// NOTE 多数のバリエーションが想定されるため、実数値とする
.a-buttonContainer {
  display: block;

  &.-w-250 {
    margin-inline: auto;
    max-width: 250px;
  }
  //&.-md {
  //  @include buttonContainer('md');
  //}
  //&.-lg {
  //  @include buttonContainer('lg');
  //}
  &.-w-480 {
    margin-inline: auto;
    max-width: 480px;
  }
}
