@use 'css/abstracts' as *;

/**
 * 挙動について
 * xlまでは、ヘッダーはstickyで、サイドバーは1カラム落ちしている
 */
.site-container {
  position: relative;

  @include layout-site-header-full-breakpoint {
    overflow-x: hidden;
  }

  .o-header {
    position: sticky;
    top: 0;
    z-index: 1000;

    @include layout-site-header-full-breakpoint {
      position: relative;
    }
  }
}

.site-main {
  // xl以下はheaderをstickyにしたのでsite-containerでhiddenできないためsite-mainに一応設定する
  // xl以上は画面幅いっぱいの表示要素があるためhiddenにすると消えるためvisibleに設定
  overflow-x: hidden; // 横スクロール対策...

  @include layout-site-header-full-breakpoint {
    overflow-x: visible;
  }
}
