@use 'css/abstracts' as *;

// @see https://www.notion.so/eaf8130292d04ebbafc1c447c18c2f3e

$_bp: 'xl';

@mixin ui-to-horizontal-breakpoint($_bp) {
  @include mq($until: $_bp) {
    @content;
  }
}

@mixin ui-to-vertical-breakpoint($_bp) {
  @include mq($_bp) {
    @content;
  }
}

.o-globalSearchButtons {
  --_size-y: 48px;
  --_size-x: 48px;
  background-color: var(--global-color-white);

  @include ui-to-horizontal-breakpoint($_bp) {
    height: 48px;
  }
  @include ui-to-vertical-breakpoint($_bp) {
    width: var(--_size-x);
    height: auto;
  }

  &.-is-user-logged-in {

  }
}

.o-globalSearchButtons__inner {
  position: relative;
  display: grid;
  gap: 2px;
  @include ui-to-horizontal-breakpoint($_bp) {
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
  }

  @include ui-to-vertical-breakpoint($_bp) {
    grid-template-columns: var(--_size-x);
  }
}

.o-globalSearchButtons__button {
  position: relative;
  @include ui-to-horizontal-breakpoint($_bp) {

  }
  @include ui-to-vertical-breakpoint($_bp) {
    //height: fit-content;
    //max-height: 232px; // IE,edgeで高さがおかしくなるので,bp以上で最大高さをとる
    * {
      writing-mode: vertical-rl;
    }
  }

  & > a,
  & > button,
  .a-btn {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    width: 100%;

    @include ui-to-horizontal-breakpoint($_bp) {
      padding: 0 1em;
      height: var(--_size-y);
      font-size: rem(12);

      .a-icon {
        display: none;
      }
    }
    @include ui-to-vertical-breakpoint($_bp) {
      padding: 1em 0;
      width: var(--_size-x);
      font-size: rem(15);
    }
  }

  // 「求人検索」ボタン
  &.-use-search {
    .for-narrow {
      @include disappear($_bp);
      //width: 100%;
      //height: 100%;
    }

    .for-wide {
      @include appear($_bp);
      //width: 100%;
      //height: 100%;
    }
  }

  // 「まずは登録する」ボタン
  &.-toForm {
    .o-globalSearchButtons__buttonToForm.-narrow {
      @include disappear($_bp);
    }

    .o-globalSearchButtons__buttonToForm.-wide {
      @include appear($_bp);

    }

    @include mq($_bp) {
      .a-icon {
        //ie
        //@media all and (-ms-high-contrast: none) {
        //  position: absolute;
        //  top: rem(16);
        //}
        ////edge
        //@supports (-ms-ime-align:auto) {
        //  position: absolute;
        //  top: rem(16);
        //}
      }
      .a-btn {
        //padding-left: px(21);
        //padding-right: px(20);
        //ie
        //@media all and (-ms-high-contrast: none) {
        //  padding-top: px(24);
        //  padding-bottom: px(6);
        //}
        //edge
        //@supports (-ms-ime-align:auto) {
        //  padding-top: px(24);
        //  padding-bottom: px(6);
        //}
      }
    }

    .-br-narrow {
      @include disappear($_bp);
    }
  }

  // 「気になるリストに～」ボタン
  &.-favorite {
    .m-bookmarkRequestButton {

    }
  }

  // 「応募履歴」ボタン
  &.-use-myPage-history {
    .a-btn {
      .a-icon {
        display: none;
      }
    }
  }
}

// 「求人検索」用のモーダル
.o-globalSearchButtons__SearchModal {
  display: none;
}
