// NOTE ヘッダーでのボタンのサイズ感の調整は、ヘルパークラスでゴリゴリやるか、カプセル化するか要検討
.o-headerMenus {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--global-spacing-1);
}

.o-headerMenus__row {
  display: grid;
  gap: var(--global-spacing-1);

  // 訪問者(未ログイン)
  &.-use-notLoggedIn {
    grid-template-columns: 204px 1fr 1fr;
  }

  // ログイン時メニュー
  &.-use-loggedIn {
    grid-template-columns: repeat(3, 1fr);
  }

  // 告知バー
  &.-use-newArrivalBar {

  }
}
.o-headerMenus__row__col {
  &.-use-postAJob {

  }
}
