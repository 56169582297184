@use "sass:math";
@use 'css/abstracts' as *;

$m-jobInfoCard__img_w: 354; // @note spデザインだと比率違う。PCに準拠
$m-jobInfoCard__img_h: 200;
$m-jobInfoCard__endText_w: 94;
$__body_padding: 20;
$__body_gap: 8;
$__save_w: 40;

.m-jobInfoCard {
  --_body-padding: 20px;
  overflow: hidden;
  font-weight: 500;
  background-color: var(--global-color-white);

  &:not(.-end) {
    @include hover {
      opacity: var(--global-ui-disabled-opacity);
    }
  }
}

.m-jobInfoCard__inner {
  position: relative;
}

.m-jobInfoCard__body {
  padding: var(--_body-padding);
  display: grid;
  //grid-template-columns: 1fr px($m-jobInfoCard__save_fz);
  grid-gap: 0 var(--global-spacing-2);
  gap: 0 var(--global-spacing-2);
  //grid-template-areas: "name name" "data save";
  grid-template: 'name name' auto 'data save' auto / 1fr px($__save_w);
}

.m-jobInfoCard__footer {
  overflow: hidden;
  border-top: px(1) solid var(--global-color-gray-300);
  //height: px(130);
  padding: 8px var(--_body-padding);
  height: auto !important;
}

// NOTE これ今使われてる?
.m-jobInfoCard__tags {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  //height: 100%;
  padding: var(--_body-padding);
  height: 130px; // @note 130 - 20 * 2 = 90

  .m-tags.-more {
    &::before {
      bottom: calc(89% / 3); // @note tagの大きさ
    }
  }
}

// 応募完了日
.m-jobInfoCard__applied {
  @include initialize-form-component-typography-small;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: .5em;
}

.m-jobInfoCard__new {
}

.m-jobInfoCard__link {
  @include link-stretched;
  display: block;
}

.m-jobInfoCard__thumb {
  position: relative;
  display: block;
  //padding-top: percentage($m-jobInfoCard__img_h/$m-jobInfoCard__img_w);
  //height: 0;
}

.m-jobInfoCard__img {
  aspect-ratio: 254 / 143; // NOTE `/`でレンダリングされているサイズ
  object-fit: cover;
  //vertical-align: bottom; // コンテナ株に隙間ができないように
  display: block;
  width: 100%;
}

.m-jobInfoCard__description {
  $fz: 16;
  $lh: 1.4;
  display: block;
  //overflow: hidden;
  //position: absolute;
  //left: 0;
  //bottom: px(10);
  //right: px(22);
  background-color: var(--global-color-black);
  font-size: rem($fz);
  line-height: $lh;
  color: var(--global-color-white);
  padding: px(6) px(8) px(6);

  .text {

    //@include line-clamp--crossBrowser(
    //  $line-clamp: 2,
    //  $line-height: $lh,
    //  $background-color: var(--global-color-black)
    //);
    @include line-clamp(2);
  }
}

.m-jobInfoCard__company {
  grid-area: name;
  font-size: var(--global-typography-fontSize-lg);
  line-height: var(--global-typofgraphy-lineHeight-sm);
}

// データリスト
.m-jobInfoCard__data {
  grid-area: data;
  margin-top: var(--global-spacing-4);
  font-size: var(--global-typography-fontSize-sm);
  line-height: var(--global-typofgraphy-lineHeight);
  color: var(--global-color-gray-400);

  li {
    position: relative;
    padding-left: px(21);

    .a-icon {
      position: absolute;
      top: 4px;
      left: 0;
    }

    & + li {
      margin-top: var(--global-spacing-1);
    }
  }
}

// 気になるボタンラッパー
.m-jobInfoCard__save {
  position: relative;
  grid-area: save;
  align-self: end;
  z-index: 2;

  .m-buttonBookmarkIcon {
    z-index: 2; // @note IE11でここもz-index: 2にしないとモダンブラウザと違って触れなかった
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
  }
}

.m-jobInfoCard__end {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);
}

.m-jobInfoCard__endText {
  @include centerer;
  width: 100%;
  font-size: rem(20);
  letter-spacing: .2em;
  text-align: center;
  color: var(--global-color-white);
}

.m-jobInfoCard.-bordered {
  .m-jobInfoCard__body {
    border: 1px solid var(--global-color-gray-200);
    border-top-width: 0;
  }
}


.m-jobInfoCard.-interview {
}

.m-jobInfoCard.-inventory {
  $_bp: "md";
  $_garter-basic: 24;
  $_garter-wide: 40;

  @include background-color-200;

  .m-jobInfoCard__inner {
    display: flex;
    flex-direction: column;
    @include mq($_bp) {
      flex-direction: row;
    }
  }

  .m-jobInfoCard__body {
    display: block;
    padding: 0 px(14) px($_garter-basic);
    @include mq($_bp) {
      flex: 1;
      padding: px($_garter-wide) px($_garter-basic) px($_garter-wide) 0;
    }

    .row {
      display: flex;
      flex-direction: column;
      @include mq($_bp) {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
      }

      .link {
        text-align: center;
        margin: px(20) auto 0;
        width: px(178);
        @include mq($_bp) {
          text-align: left;
          margin-top: 0;
        }

        .a-btn {
          //@include a-btn_size(42, 16);
          font-size: 1rem;
          min-height: 42px;
          padding-right: px($_garter-wide);
          padding-left: px($_garter-wide);
          font-weight: 700;
        }
      }
    }
  }

  .m-jobInfoCard__company {
    margin-top: px(10);
    font-size: rem(18);
  }

  .m-jobInfoCard__data {
    margin-top: px(8);
    font-size: rem(14);
    @include mq($_bp) {
      flex: 1;
    }
  }

  .m-jobInfoCard__thumb {
    position: relative;
    width: 100%;
    padding: px(14);

    img {
      width: 100%;
    }

    @include mq($_bp) {
      width: px(272);
      padding: px($_garter-basic);
      img {
        @include centerer;
        width: px(224);
        height: px(190);
        object-fit: cover;
      }
    }
  }

  .m-jobInfoCard__description {
    font-size: rem(18);
    padding: 0;
    background-color: transparent;
    font-weight: bold;
    color: inherit;

    .text {
      background-color: inherit;
      color: inherit;
    }
  }

  .m-jobInfoCard__pref,
  .m-jobInfoCard__income {
    display: block;
    margin-top: px(3);
  }
}

// 2022/10現在多様されているが・・・
.m-jobInfoCard.-searchResult {

}

.m-jobInfoCard.-sidebar {
  .m-jobInfoCard__body {
    display: block;
    padding: px(10) px(10) px(12);
  }

  .m-jobInfoCard__img {
    width: 100%;
  }

  .m-jobInfoCard__data {
    display: flex;
    flex-wrap: wrap;
    margin-top: px(10);

    & > * {
      display: inline-flex;
      min-width: 0;
      max-width: px(230); // ここは決め打ちするしかありません
    }

    .m-jobInfoCard__industry,
    .m-jobInfoCard__job {
      width: 100%;
    }


    .m-jobInfoCard__pref {
      width: percentage(math.div(60, 230));
    }

    .m-jobInfoCard__income {
      width: percentage(math.div(150, 230));
    }

    // 3点リーダーが適用されるようにする
    // 親にwidthが指定されていないとうまく動かないと思います。
    .label {
      @include ellipsis;
    }
  }
}

.m-jobInfoCard.-sidejob {
  .m-jobInfoCard__body {
    //padding: px($__body_padding);
    display: grid;
    //grid-template-columns: 1fr px($m-jobInfoCard__save_fz);
    grid-gap: 0 0;
    gap: 0 0;
    //grid-template-areas: "name name" "data save";
    grid-template: 'name name' auto 'data save' auto / 1fr 0;
  }
}

.m-jobInfoCard.-simple {
  // -simple. 通称「Bパターン」
  $_bp: 'md';
  position: relative;

  %appear-sync-searchContentHead {
    @include appear($_bp);
  }

  .m-jobInfoCard__inner {
    @include mq($until: $_bp) {
      position: static;
    }
  }

  .m-jobInfoCard__thumb {
    @extend %appear-sync-searchContentHead;
    @include mq($until: $_bp) {
      position: static;
    }
  }

  .m-jobInfoCard__body {
    @include mq($until: $_bp) {
      padding-block: var(--global-spacing-4);
    }

    .m-jobInfoCard__end {
      @include disappear($_bp);
      background-color: rgba(0, 0, 0, .25);
      z-index: 10;
    }

    // カードUI右上の頂点をテキスト中央と軸で揃うようなイメージ
    .m-jobInfoCard__endText {
      $_tweak: 8;
      position: absolute;
      top: px($m-jobInfoCard__endText_w * -1 + $_tweak);
      right: px($m-jobInfoCard__endText_w * -1 + $_tweak);
      //top: 0;
      //right: 0;
      left: auto;
      bottom: auto;
      transform: rotate(45deg);
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: px(4);
      width: px(sqrt(3) * $m-jobInfoCard__endText_w);
      height: px(sqrt(3) * $m-jobInfoCard__endText_w);
      background-color: var(--global-color-black);
      font-size: rem(16);
      line-height: 1;
      color: var(--global-color-white);
    }
  }

  .m-jobInfoCard__new {
    @extend %appear-sync-searchContentHead;
  }

  .m-jobInfoCard__company {
    $_fz: 18;
    @include mq($until: $_bp) {
      font-size: rem($_fz);
      line-height: math.div(23.4, $_fz);
    }
  }

  .m-jobInfoCard__data {
    $_fz: 14;
    $_lh: math.div(19.25, $_fz);
    @include mq($until: $_bp) {
      margin-top: px(15 - calcOneSideBetweenTheLines($_fz, $_lh));
      display: flex;
      flex-wrap: wrap;
      font-size: rem($_fz);
      line-height: $_lh;

      li {
        & + li {
          margin-top: px(12 - calcOneSideBetweenTheLines($_fz, $_lh) * 2);
        }
      }
    }
  }

  .m-jobInfoCard__job {
    @include mq($until: $_bp) {
      flex: 1 1 100%;
      min-width: 100%;
    }
  }

  .m-jobInfoCard__pref {
    @include mq($until: $_bp) {
      margin-right: var(--global-spacing-2);
      flex-shrink: 1;
    }
  }

  .m-jobInfoCard__income {
    @include mq($until: $_bp) {
      flex-grow: 1;
    }
  }

  .m-jobInfoCard__footer {
    @extend %appear-sync-searchContentHead;
  }
}
