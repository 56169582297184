@use 'css/abstracts' as *;

.m-buttonBookmark {
  --_color-primary: var(--global-color-primary);
  --_color-primary-contract: var(--global-color-primary-contract);
  @include link-stretched;
  @include can-click-element;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: var(--global-spacing-3) var(--global-spacing-4);
  border: 1px solid var(--_color-primary);
  background-color: var(--_color-primary-contract);
  font-size: var(--global-typography-fontSize-default);
  line-height: 1;
  color: var(--_color-primary);

  &:hover {
    opacity: var(--global-ui-hover-opacity);
  }

  // ブックマークされていない
  &:not([data-bookmarked]),
  &[data-bookmarked="false"] {
    .-saved {
      display: none;
    }
  }

  // ブックマークされている
  &[data-bookmarked="true"] {
    background-color: var(--_color-primary);
    border-color: var(--_color-primary);
    color: var(--_color-primary-contract);

    .-save {
      display: none;
    }
  }

  // 塗りつぶし状態
  &.-theme-fill {
    background-color: var(--_color-primary);
    border-color: var(--_color-primary);
    color: var(--_color-primary-contract);
  }
}

.m-buttonBookmark__label {
  display: flex;
  gap: var(--global-spacing-2-5);
  justify-content: center;
  align-items: center;
}

.m-buttonBookmark__icon {
  font-size: 1em;
  width: 1em;
  height: 1em;

  .a-icon {
    //margin-right: 10px;
    //margin-inline-end: var(--global-spacing-2-5);
    //position: relative;
    //margin-block-start: calc(var(--global-spacing-1) * -1);
    //vertical-align: top;
  }
}

.m-buttonBookmark.-mini {
  //padding-right: 0;
  //padding-left: 0;

  .m-buttonBookmark__label {
    gap: var(--global-spacing-1);
    flex-direction: column;
    text-align: center;
  }

  .m-buttonBookmark__text {
    font-size: var(--global-typography-fontSize-xs);
  }

  &[data-bookmarked="true"] {
    background-color: #fff;
    color: var(--global-color-primary);
  }
}

.m-buttonBookmark.-full {
  width: 100%;
}
